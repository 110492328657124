import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const remainSeconds = + this.element.dataset.remain

    const t = new Date()
    t.setSeconds(t.getSeconds() + remainSeconds)

    const diff = 1 // second

    const f = () => {
      if (remainSeconds < 1 || t < new Date()) {
        this.element.textContent = "times up"
      } else {
        const remain = (t - new Date()) / 1000

        const h = Math.floor(remain / 60 / 60)
        const m = Math.floor(remain / 60) % 60
        const s = Math.floor(remain % 60)
        const [fh, fm, fs] = [h, m, s].map(v => String(v).padStart(2, '0'))


        this.element.textContent = `${fh}:${fm}:${fs}`
      }
    }
    f()

    setInterval(f, diff * 1000)
  }
}
