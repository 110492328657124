import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  connect() {
    const wrapper = this.element

    if (!wrapper.dataset.max){
      return
    }

    this.inputTarget.addEventListener("change", function(){
      wrapper.classList.remove("field_with_errors")
      wrapper.querySelectorAll(".error").forEach(el => el.remove())
      if(this.files[0].size > wrapper.dataset.max * 1024 * 1024){
        this.value = "";
        wrapper.classList.add("field_with_errors")
        wrapper.insertAdjacentHTML("beforeend", "<span class='error'>over size</span>")
      }
    })
  }
}
