import CheckboxSelectAll from 'stimulus-checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ["checkboxAll", "checkbox", "sBtn", "hBtn"]
  
  connect() {
    super.connect()

    // Get all checked checkboxes
    this.checked

    // Get all unchecked checkboxes
    this.unchecked

    this.checkboxAllTarget.addEventListener('change', () => {
      this.toggleBtns()
    })
    this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', () => {
      this.toggleBtns()
    }))
  }

  toggleBtns() {
    if (this.checked.length > 0) {
      this.hBtnTargets.forEach(el => el.classList.remove("hidden"))
      this.sBtnTargets.forEach(el => el.classList.add("hidden"))
    } else {
      this.hBtnTargets.forEach(el => el.classList.add("hidden"))
      this.sBtnTargets.forEach(el => el.classList.remove("hidden"))
    }
  }
}
