import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const row = this.element
    row.addEventListener("click", (e) => {
      let skip = false;
      const blacklist = ["INPUT", "BUTTON", "A"];
      if (blacklist.includes(e.target.tagName)) {
        skip = true;
      }

      blacklist.forEach((tagName) => {
        if (e.target.closest(tagName)){
          skip = true;
        }
      });

      if (skip) {
        return;
      }

      row.querySelectorAll("input[type=checkbox]").forEach(el => el.click())
    })
  }
}
